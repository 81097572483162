.mapbox-gl-highlight_selection:before {
  content: 'touch_app';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: black;
}
.mapbox-gl-highlight_selection.active:before {
  content: 'touch_app';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: #33b5e5;
}
.mapbox-gl-implantation.mode-releve:before {
  content: 'square_foot';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: #33b5e5;
}
.mapbox-gl-implantation.mode-implantation:before {
  content: 'place';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: #ffc107;
}
.mapbox-gl-implantation.mode-intervention:before {
  content: 'build';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: #ff4081;
}
.mapbox-gl-draw_polygon:before {
  content: 'select_all';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: black;
}
.mapbox-gl-draw_polygon.active:before {
  content: 'select_all';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: #33b5e5;
}
.mapbox-gl-draw_point:before {
  content: 'location_on';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: black;
}
.mapbox-gl-draw_extends:before {
  content: 'zoom_out_map';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: black;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-style-custom-ign:before {
  content: 'apps';
  font-family: 'Material Icons';
  font-size: 20px;
  vertical-align: middle;
  color: black;
}
.mapboxgl-ctrl-top-left {
  z-index: 1000;
}
.is-mobile {
  .mapboxgl-ctrl-top-left {
    z-index: unset !important;
  }
}
.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}
.mapboxgl-ctrl-group .mapboxgl-style-list button {
  color: black;
}
