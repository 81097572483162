// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  /* --ion-color-primary: #3880ff; */
  --ion-color-primary: #2a3584;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  /* --ion-color-secondary: #3dc2ff; */
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** FIGMA **/
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --black-087: rgba(0, 0, 0, 0.87);
  --black-012: rgba(0, 0, 0, 0.12);
  --shades--black: rgba(0, 0, 0, 1);
  --shades--grey: rgba(0, 0, 0, 0.6);
  --shades--white: rgba(255, 255, 255, 1);
  --grey: rgba(245, 245, 245, 1);
  --primary: rgba(57, 65, 137, 1);
  --primary-dark: rgba(0, 27, 91, 1);
  --primary-light: rgba(106, 108, 185, 1);
  --error-dark: rgba(215, 0, 18, 1);
  --error-regular: rgba(246, 36, 32, 1);
  --error-light: rgba(255, 72, 65, 1);
  --warning-dark: rgba(202, 111, 32, 1);
  --warning-regular: rgba(216, 138, 40, 1);
  --warning-light: rgba(224, 163, 62, 1);
  --valid-dark: rgba(0, 140, 59, 1);
  --valid-regular: rgba(0, 176, 84, 1);
  --valid-light: rgba(93, 212, 145, 1);
  --non-pose: rgba(255, 241, 118, 1);
  --imported-mapillary: rgb(156, 156, 156);

  --default-margin: 16px;
  --default-header-height: 64px;
  --default-panel-width: 524px;
  --150px: 150px;
  --200px: 200px;
  --300px: 300px;
  --400px: 400px;

  --100vh-wt-top: calc(100vh - env(safe-area-inset-top));
  --100vh-wt-top-bottom: calc(
    100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top)
  );

  --v-text--h1: 88px;
  --v-text--h2: 57px;
  --v-text--h3: 48px;
  --v-text--h4: 34px;
  --v-text--h5: 23px;
  --v-text--h6: 20px;
  --v-text--subtitle-1: 15px;
  --v-text--subtitle-2: 14px;
  --v-text--body-1: 16px;
  --v-text--body-2: 14px;
  --v-text--button: 14px;
  --v-text--caption: 12px;
  --v-text--overline: 12px;
  --v-icon--x-small: 12px;
  --v-icon--small: 16px;
  --v-icon--dense: 20px;
  --v-icon--medium: 24px;
  --v-icon--large: 36px;
  --v-icon--x-large: 40px;

  --default: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 1px rgba(0, 0, 0, 0.2);
  --active: 0px 5px 14px rgba(0, 0, 0, 0.12), 0px 9px 10px rgba(0, 0, 0, 0.14),
    0px 5px 5px rgba(0, 0, 0, 0.2);
  --elevation--0: 0px 0px 0px rgba(0, 0, 0, 0.2);
  --elevation--1: 0px 2px 1px rgba(0, 0, 0, 0.2);
  --elevation--2: 0px 3px 1px rgba(0, 0, 0, 0.2);
  --elevation--3: 0px 3px 3px rgba(0, 0, 0, 0.2);
  --elevation--4: 0px 2px 4px rgba(0, 0, 0, 0.2);
  --elevation--5: 0px 3px 5px rgba(0, 0, 0, 0.2);
  --elevation--6: 0px 3px 5px rgba(0, 0, 0, 0.2);
  --elevation--7: 0px 4px 5px rgba(0, 0, 0, 0.2);
  --elevation--8: 0px 5px 5px rgba(0, 0, 0, 0.2);
  --elevation--9: 0px 5px 6px rgba(0, 0, 0, 0.2);
  --elevation--10: 0px 6px 6px rgba(0, 0, 0, 0.2);
  --elevation--11: 0px 6px 7px rgba(0, 0, 0, 0.2);
  --elevation--12: 0px 7px 8px rgba(0, 0, 0, 0.2);
  --elevation--13: 0px 7px 8px rgba(0, 0, 0, 0.2);
  --elevation--14: 0px 7px 9px rgba(0, 0, 0, 0.2);
  --elevation--15: 0px 8px 9px rgba(0, 0, 0, 0.2);
  --elevation--16: 0px 8px 10px rgba(0, 0, 0, 0.2);
  --elevation--17: 0px 8px 11px rgba(0, 0, 0, 0.2);
  --elevation--18: 0px 9px 11px rgba(0, 0, 0, 0.2);
  --elevation--19: 0px 9px 12px rgba(0, 0, 0, 0.2);
  --elevation--20: 0px 10px 13px rgba(0, 0, 0, 0.2);
  --elevation--21: 0px 10px 13px rgba(0, 0, 0, 0.2);
  --elevation--22: 0px 10px 14px rgba(0, 0, 0, 0.2);
  --elevation--23: 0px 11px 14px rgba(0, 0, 0, 0.2);
  --elevation--24: 0px 11px 15px rgba(0, 0, 0, 0.2);
}

// @media (prefers-color-scheme: dark) {
//    * Dark Colors
//    * -------------------------------------------
//    */
//
//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;
//
//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;
//
//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;
//
//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;
//
//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;
//
//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;
//
//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;
//
//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;
//
//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }
//
//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */
//
//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;
//
//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;
//
//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;
//
//     --ion-toolbar-background: #0d0d0d;
//
//     --ion-item-background: #000000;
//
//     --ion-card-background: #1c1c1d;
//   }
//
//
//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */
//
//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;
//
//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;
//
//     --ion-border-color: #222222;
//
//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;
//
//     --ion-item-background: #1e1e1e;
//
//     --ion-toolbar-background: #1f1f1f;
//
//     --ion-tab-bar-background: #1f1f1f;
//
//     --ion-card-background: #1e1e1e;
//   }
// }
