@font-face {
  font-family: 'DIN Medium';
  src:
    url('../assets/fonts/DINMedium.eot') format('embedded-opentype'),
    url('../assets/fonts/DINMedium.woff') format('woff'),
    url('../assets/fonts/DINMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'DIN Regular';
  src: url('../assets/fonts/DinRegular.woff') format('woff');
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}