/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@use '@angular/material' as mat;
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import '@watergis/mapbox-gl-export/css/styles.css';
@import 'mapbox-gl-style-switcher/styles.css';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import './theme/popup.scss';
@import './theme/mapbox.scss';

@include mat.core();
$md-mcgpalette0: (
  50: #e7e8f1,
  100: #c4c6dc,
  200: #9ca0c4,
  300: #747aac,
  400: #575e9b,
  500: #394189,
  600: #333b81,
  700: #2c3276,
  800: #242a6c,
  900: #171c59,
  A100: #959cff,
  A200: #626cff,
  A400: #2f3cff,
  A700: #1525ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$my-primary: mat.define-palette($md-mcgpalette0, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

@include mat.all-component-themes($my-theme);

html:not(.plt-android):not(.plt-phablet):not(.plt-mobile):not(
    .plt-mobileweb
  ):not(.md) {
  --scrollbar-bg-color: #fff;
  --scrollbar-thumb-color: #babac0;
  --scrollbar-thumb-radius: 16px;
  --scrollbar-thumb-border: 4px solid #fff;
  --scrollbar-width: 16px;
}
html:not(.plt-android):not(.plt-phablet):not(.plt-mobile):not(
    .plt-mobileweb
  ):not(.md)
  ::-webkit-scrollbar {
  background-color: var(--scrollbar-bg-color);
  width: var(--scrollbar-width);
}

html:not(.plt-android):not(.plt-phablet):not(.plt-mobile):not(
    .plt-mobileweb
  ):not(.md)
  ::-webkit-scrollbar-track {
  background-color: var(--scrollbar-bg-color);
}

html:not(.plt-android):not(.plt-phablet):not(.plt-mobile):not(
    .plt-mobileweb
  ):not(.md)
  ::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: var(--scrollbar-thumb-radius);
  border: var(--scrollbar-thumb-border);
}

html:not(.plt-android):not(.plt-phablet):not(.plt-mobile):not(
    .plt-mobileweb
  ):not(.md)
  ::-webkit-scrollbar-button {
  display: none;
}

.cdk-global-scrollblock {
  overflow: auto !important;
}

:root {
  --ion-color-secondary: rgb(0, 128, 0);
  --ion-font-family: 'DIN Regular';
}

html,
body {
  font-size: 16px !important;
  margin: 0px !important;
}

.ion-no-padding {
  --inner-padding-end: 0px;
}
.p-relative {
  position: relative;
}
.d-block {
  display: block;
}
.d-inlineblock {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-flex-right {
  display: flex;
  justify-content: flex-end;
}
.f0 {
  flex: 0;
}
.f1 {
  flex: 1;
}
.fb20 {
  flex-basis: 20%;
}
.fb25 {
  flex-basis: 25%;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.flex-sa {
  justify-content: space-around;
}
.flex-sb {
  justify-content: space-between;
}
.flex-jc {
  justify-content: center;
}
.flex-se {
  justify-content: space-evenly;
}
.flex-jend {
  justify-content: end;
}
.flex-ac {
  align-items: center;
}
.flex-aend {
  align-items: end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow-1,
.fg-1 {
  flex-grow: 1;
}
.d-column {
  display: flex !important;
  flex-direction: column;
}
.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-scroll {
  overflow-y: hidden;
}
.no-scroll .scroll-content {
  overflow-y: hidden;
}

.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.h100vh {
  height: 100vh;
}
.f20 {
  font-size: 20px !important;
}
.f19 {
  font-size: 19px !important;
}
.f18 {
  font-size: 18px !important;
}
.f17 {
  font-size: 17px !important;
}
.f16 {
  font-size: 16px !important;
}
.f15 {
  font-size: 15px !important;
}
.f14 {
  font-size: 14px !important;
}
.lh05 {
  line-height: 0.5em;
}
.lh075 {
  line-height: 0.75em;
}
.input-photo {
  label {
    display: flex;
    align-items: center;
  }
  input {
    display: none;
  }
}
.m1 {
  margin: 0.5em;
}
.m1em {
  margin: 1em;
}
.mr1em {
  margin-right: 1em;
}
.ml1em {
  margin-left: 1em;
}
.mt1em {
  margin-top: 1em;
}
.mb1em {
  margin-bottom: 1em;
}
.p0 {
  padding: 0em;
}
.p1,
.p05em {
  padding: 0.5em;
}
.p1em {
  padding: 1em;
}
.pb1em {
  padding-bottom: 1em;
}
.pt1em {
  padding-top: 1em;
}
.pr1em {
  padding-right: 1em;
}
.pl1em {
  padding-left: 1em;
}
.toolbar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
}

.shad-border {
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  border-radius: 5px;
}
.shad-border-2 {
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.8);
  border-radius: 5px;
}
.f-i {
  font-style: italic;
}
.f-din {
  font-family: 'DIN Regular';
}
.is-secondary {
  color: var(--ion-color-secondary);
}
.is-danger {
  color: var(--ion-color-danger);
}
.c-white {
  color: white;
}
.c-black {
  color: black;
}
.m-auto {
  margin: auto;
}
.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1;
  color: black;
}
.native-input[disabled].sc-ion-input-md {
  opacity: 1;
}
.loading-wrapper,
.alert-wrapper .alert-title,
.alert-wrapper .alert-button {
  font-family: 'DIN Regular';
}
.alert-wrapper .alert-message {
  font-family: 'DIN Regular';
}
.bg-red {
  background-color: rgb(245, 61, 61);
}
.bg-grey {
  background-color: rgb(244, 244, 244);
}
.bg-green {
  background-color: rgb(0, 128, 0) !important;
}
.icon-green {
  color: rgb(0, 128, 0) !important;
}
.carre {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

@media print {
  #hubspot-messages-iframe-container {
    display: none !important;
  }
  @page {
    size: auto;
    margin: 0mm;
  }
  body {
    margin: 1cm;
    position: initial !important;
    max-height: initial !important;
    overflow: auto !important;
  }
}
.break-spaces {
  white-space: break-spaces;
}
.break {
  flex-basis: 100%;
  height: 0;
}
div.tooltipd3 {
  position: absolute;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 999;
}
.photo-emplacement {
  max-height: calc(var(--100vh-wt-top) - 250px) !important;
}
@media (max-width: 992px) {
  .not-mobile {
    display: none !important;
  }
}
.redIcon {
  fill: var(--ion-color-danger);
}
.swiper-pagination-bullet {
  border: 1px solid var(--bullet-background-active);
}
.mat-list-base .sub-mat-item .mat-list-item .mat-list-item-content {
  align-items: flex-start;
}
.mat-list-base .sub-mat-item .mat-list-item {
  height: 48px !important;
}
.mat-subheader {
  text-transform: uppercase;
  color: var(--shades--grey);
  font-size: 0.875em;
}
.mat-form-field-wrapper {
  padding-bottom: var(--default-margin);
}
.mat-toolbar:not(.bottom-toolbar) {
  > div:first-of-type {
    flex: 1 0 auto;
    margin-right: var(--default-margin);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-indent: 20px;
    color: #ffffff;
  }
  > span:first-of-type {
    flex: 1 0 auto;
    margin-right: var(--default-margin);
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-indent: 20px;
    color: #ffffff;
  }
  > button {
    flex: 0 0 40px;
  }
}
.mat-secondary {
  color: white;
  background-color: var(--valid-regular);
}
.mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.toolbar-spacer {
  flex: 1 1 auto;
}
.spacer {
  width: 32px;
}
.swiper-slide {
  flex-direction: column;
}
.mat-select-panel-wrap .mat-option-color-valid {
  color: var(--valid-regular) !important;
}
.mat-select-panel-wrap .mat-option-color-danger {
  color: var(--error-regular) !important;
}
.mat-icon-primary {
  color: var(--primary);
}
img.big {
  max-width: 104px;
  max-height: 92px;
}
img.small {
  width: 48px;
  height: 48px;
}
.pointer {
  cursor: pointer;
}
.hidden {
  visibility: hidden;
  display: none !important;
}
.mat-tooltip {
  white-space: pre-line !important;
}
.flex1 {
  flex: 1;
}
.mat-option-color-valid .mdc-list-item__primary-text {
  color: var(--valid-regular) !important;
}
.mat-option-color-danger .mdc-list-item__primary-text {
  color: var(--error-regular) !important;
}
.mat-option-color-warning .mdc-list-item__primary-text {
  color: var(--warning-regular) !important;
}
.mdc-snackbar.ugau-toast {
  .mdc-snackbar__surface {
    background-color: #394189;
  }
}
app-home.multiple-print-hidden app-emplacement {
  z-index: -1;
}
.mdc-tooltip {
  white-space: pre-line;
}
.mdc-tooltip__surface {
  background-color: #394189 !important;
  border: 1px solid white;
}
.boxdraw {
  background: rgba(56, 135, 190, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
.arcade-iframe .mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: unset !important;
}
.cdk-overlay-pane {
  max-height: 100vh;
}
.rotate {
  animation: rotation 650ms infinite linear;
}
.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
#hubspot-messages-iframe-container {
  color-scheme: none !important;
}
.material-icons.refresh.refreshing:before {
  animation: spin 1s linear infinite;
}
.material-icons.refresh:before {
  content: 'refresh';
  font-family: 'Material Icons';
  font-size: 29px;
  color: black;
}

.selectedSort {
  span {
    font-weight: bold;
  }
}
.cdk-overlay-container {
  z-index: 1005;
}
.increase15 {
  transform: scale(1.5);
}
.text-center {
  text-align: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
