// Gestion de la popup
$primary-color: #394189;
$hover-color: #2f3566;
$conforme-color: #4caf50;
$non-conforme-color: #f44336;
$close-button-size: 32px;
$box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
$border-radius: 8px;

.ugau-popup {
  max-width: 240px;

  border-radius: $border-radius;
  overflow: hidden;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  &.conforme .ugau-button-popup {
    border-color: $conforme-color;
    background-color: $conforme-color;
    &:hover {
      background-color: $conforme-color;
      filter: brightness(80%);
    }
  }

  &.non-conforme .ugau-button-popup {
    border-color: $non-conforme-color;
    background-color: $non-conforme-color;
    &:hover {
      background-color: $non-conforme-color;
      filter: brightness(80%);
    }
  }

  .mapboxgl-popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 18px;
    padding-bottom: 0;
    box-shadow: $box-shadow;
    position: relative; // Add this to position the bottom container absolutely
  }

  .mapboxgl-popup-close-button {
    color: black;
    font-size: 24px;
    border-radius: 50%;
    background-color: white;
    width: $close-button-size;
    height: $close-button-size;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    padding-inline-start: 8px;
    padding-inline-end: 8px;

    &:hover {
      background-color: #f2f2f2;
      transform: scale(1.1);
    }
  }

  .ugau-button-popup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    color: white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    padding: 10px 15px;
    cursor: pointer;
    text-align: center;
    width: 100%; // Pour occuper toute la largeur disponible
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      filter: brightness(80%);
    }

    span:first-child {
      flex: 0 0 40px;
    }
    span:last-child {
      flex: 1;
    }
  }

  img.w100 {
    width: 100%; // Image prend toute la largeur
    border-radius: $border-radius $border-radius 0 0;
    min-height: 220px;
    min-width: 220px;
  }

  .mdc-list-group__subheader {
    min-height: 32px;
  }

  .image-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    left: 0;
    right: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); // Semi-transparent background for better visibility
    padding: 5px; // Some padding for spacing
    z-index: 1; // Ensure it stays above the image
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .image-banner-container::before,
  .image-banner-container::after {
    content: ''; /* Génère des pseudo-éléments avant et après */
    flex: 1 0 auto; /* Les rend flexibles */
  }

  // Style for the individual component images (img-xxx divs)
  .image-banner-container > div[class^='img-'] {
    margin: 5px; // Space between the images
    border-radius: 4px; // Optional: give them rounded corners
    background-color: white; // Optional: give them a white background
    flex-grow: 0;
    flex-shrink: 0;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    .image-banner-container {
      position: relative;
    }
    .ugau-button-popup {
      padding: 10px 0;
      gap: 0;
    }
  }
}
